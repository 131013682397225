import React, { useReducer } from "react";

import NotesContext from "./notesContext";
import notesReducer from "./notesReducer";


import { CHANGE_STATE } from "./types";

export const NotesState = (props) => {
  // const app_context = useContext(appContext);
  // const { dbGet } = app_context;

  const initialState = {
    notes: {
      NoteId:null,
      StudentId: "",
      CreatedDate: "",
      NoteContent: "",
      IsEnabled: 1,
      UpdatedAt: "",
    },
    oldNotes: {
      NoteId:null,
      StudentId: "",
      CreatedDate: "",
      NoteContent: "",
      IsEnabled: 1,
      UpdatedAt: "",
    },
    displayNotes:{
      NoteId:null,
      StudentId: "",
      CreatedDate: "",
      NoteContent: "",
      IsEnabled: 1,
      UpdatedAt: "",
    },
  };

  const [state, dispatch] = useReducer(notesReducer, initialState);

  const changeNotesState = (property, value) => {
    dispatch({
      type: CHANGE_STATE,
      payload: {
        property: property,
        value: value,
      },
    });
  };

  return (
    <NotesContext.Provider
      value={{
        notes: state.notes,
        oldNotes: state.oldNotes,
        displayNotes:state.displayNotes,
        changeNotesState,
      }}
    >
      {props.children}
    </NotesContext.Provider>
  );
};
