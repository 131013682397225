import { CHANGE_STATE, BLANK_NCCD, RESET_NCCD, NEW_TO_OLD } from "./types";

const reducerFunction = (state, action) => {
  switch (action.type) {
    case NEW_TO_OLD:
      return{
        ...state,
        oldCategory:state.category,
        oldSupportLevel:state.supportLevel,
        oldCaseManager:state.caseManager,
        oldValidToDate:state.validToDate
      }
    case RESET_NCCD:
      return {
        ...state,
        category: state.oldCategory,

        supportLevel: state.oldSupportLevel,

        caseManager: state.oldCaseManager,

        validToDate: state.oldValidToDate,
      };
    case BLANK_NCCD:
      return {
        ...state,
        category: null,
        oldCategory: null,
        supportLevel: null,
        oldSupportLevel: null,
        caseManager: null,
        oldCaseManager: null,
        validToDate: null,
        oldValidToDate: null,
        canReset:false,
        canSubmit:false,
        dateSame:true,
        categorySame:true,
        caseManagerSame:true,
        supportLevelSame:true,
        selectedNccdTab: 0,
        selectedNccd:null
      };
    case CHANGE_STATE:
      return {
        ...state,
        [action.payload.property]: action.payload.value,
      };

    default:
      return state;
  }
};

export default reducerFunction;
