import React, { useState,useContext } from "react";

import { Link} from "react-router-dom";
import styled from "styled-components";
import appContext from "../../context/app/appContext";

const SidebarLabel = styled.div`
  display: flex;
  gap: 0.6rem;
  padding-left:${({padLeft})=>(padLeft)};
`;

const SidebarChild = styled.div`
  flex-grow: 1;
  display:flex;
  align-items:center;
  justify-content:center;
`;

const ArrowContainer = styled.div`
  /* width: 100%; */
  /* margin-left: 20px;
  background-color: red; */
`;

const ArrowChild = styled.div`
  float: right;
`;

const SubMenu = (props) => {

  const app_context=useContext(appContext)
  
  const {privileges}=app_context

  const [subnav, setSubnav] = useState(false);

  const showSubnav = () => {
    setSubnav(!subnav);
  };

   const { item, showSidebar, pinned } = props;

   const {accessLevel}=item
  const linkFunction = () => {
    if (!pinned) {
      showSidebar();
    }
  };
 
if(privileges >=accessLevel){
return (
  <>
    <Link
      className="sideBar-link"
      to={item.path}
      onClick={item.subNav ? showSubnav : linkFunction}
    >
      <div>
        <SidebarLabel padLeft={item.padLeft}>
          <SidebarChild>{item.icon} </SidebarChild>
          <SidebarChild>{item.title}</SidebarChild>
          <ArrowContainer>
            <ArrowChild>
              {item.subNav && subnav
                ? item.iconOpened
                : item.subNav
                ? item.iconClosed
                : null}
            </ArrowChild>
          </ArrowContainer>
        </SidebarLabel>
      </div>
    </Link>
    {subnav &&
      item.subNav.map((item, index) => {
        return (
          <Link className="dropDown-link" to={item.path} key={index}>
            {item.icon}
            {item.title}
          </Link>
        );
      })}
  </>
);
} else {
  return null
}
  
};

export default SubMenu;
