import React, { useState, useRef, useEffect,useContext } from "react";
import styled from "styled-components";
import * as FaIcons from "react-icons/fa";
import * as AiIcons from "react-icons/ai";

// import * as GiIcons from "react-icons/gi";
import { SidebarData } from "./SidebarData";
import SubMenu from "./SubMenu";
// import EmailMenu from "./emailSubMenu/EmailMenu";
import Search from "../search/Search";
import { Link } from "react-router-dom";
import { SignOutIcon } from "./SignOutIcon";
import ExamMenu from "./subMenus/exam/ExamMenu";
// import NccdMenu from "./subMenus/nccd/NccdMenu";
import AdminMenu from "./subMenus/admin/AdminMenu";
import appContext from "../../context/app/appContext";

// import "./styles.css";

const Nav = styled.div`
  background: white;
  height: 70px;
  display: flex;
  justify-content: flex-start;
  /* align-items: center; */
  /* border-bottom: 1px solid black;
  border-top: 1px solid black; */
`;

const breakPoint = 1110;

const SidebarNav = styled.nav`
  background: #15171c;
  height: 100vh;
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  left: ${({ sidebar }) => (sidebar ? "0" : "-100%")};
  transition: 350ms;
  z-index: 10;
 
`;

const SearchContainer = styled.div`
  width: ${({ searchClicked }) => (searchClicked ? "100%" : "60%")};
  /* width: 100px; */
  background-color: white;
  height: 100%;
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  transition: 250ms;
  margin-left: ${({ pinned }) => (pinned ? "280px" : "0px")};
`;

const BrandContainer = styled(Link)`
  min-width: 15%;

  height: 70px;
  font-family: "Raleway", sans-serif;
  display: ${({ searchClicked }) => (searchClicked ? "none" : "flex")};
  align-items: center;
  font-size: 2rem;
  padding-right: 15px;
  padding-left: 15px;
  justify-content: center;
  cursor: pointer;
  /* transition: 250ms; */
  /* background-color: rgb(117, 55, 30); */
  text-decoration: none;
  &:hover {
    color: gold;
    font-weight: 600;
  }
`;

const LogoutContainer = styled.div`
  font-size: 1.5rem;
  height: 100%;
  display: flex;

  /* background-color: rgb(117, 55, 30); */
  align-items: center;
  color: white;
  /* flex-grow: 1; */

  max-width: 70px;
  justify-content: center;
  padding-left: 20px;
  padding-right: 20px;
  transition: 250ms;
  /* border-left: 1px solid rgb(248, 179, 31); ; */
`;

const BarsContainer = styled.div`
  font-size: 1.5rem;
  height: 100%;
  display: flex;
  /* background-color: rgb(117, 55, 30); */
  align-items: center;
  color: white;
  /* flex-grow: 1; */

  max-width: 70px;
  justify-content: center;
  padding-left: 20px;
  padding-right: 20px;
  transition: 250ms;
  /* border-right: 1px solid rgb(248, 179, 31); ; */
`;

const StyledBrandContainer = (props) => {

  
  const { name } = props;
  return (
    <BrandContainer className="brand-container" to="/">
      <div
        style={{
          display: "flex",

          gap: "1rem",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div style={{ marginTop: "-8px" }}>
          <img
            height="40px"
            src={process.env.PUBLIC_URL + "/GEODESIC.png"}
            alt=""
          />
        </div>
        {name}
      </div>
    </BrandContainer>
  );
};

const Sidebar = (props) => {
  const app_context=useContext(appContext)
  const {privileges}=app_context
  const wrapperRef = useRef();
  const searchRef = useRef();
  const [sidebar, setSidebar] = useState(() => {
    return window.innerWidth < breakPoint ? false : true;
  });
  const { pinned, setPinned, pinnable, setPinnable, setMargin } = props;

  const [searchClicked, setSearchClicked] = useState(false);

  const handlePin = () => {
    setPinned(!pinned);
    if (pinned) {
      showSidebar();
    }
  };

  const showSidebar = () => {
    setSidebar(!sidebar);
  };

  useEffect(() => {
    const handleClickOutside = (e) => {
      if (wrapperRef.current && !wrapperRef.current.contains(e.target)) {
        if (!pinned) {
          setSidebar(false);
        }
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  });
  useEffect(() => {
    const handleClickOutsideSearch = (e) => {
      if (searchRef.current && !searchRef.current.contains(e.target)) {
        setSearchClicked(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutsideSearch);
    return () => {
      document.removeEventListener("mousedown", handleClickOutsideSearch);
    };
  });

  const [width, setWidth] = useState(window.innerWidth);

  const handleResize = () => {
    setWidth(window.innerWidth);
    if (window.innerWidth > breakPoint) {
      setPinnable(true);
    } else {
      setPinnable(false);
      setSidebar(false);
      setPinned(false);
      setMargin("0");
    }
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    window.addEventListener("orientationchange", handleResize);
    return () => {
      window.removeEventListener("resize orientationchange", handleResize);
      window.removeEventListener("orientationchange", handleResize);
    };
  });

  return (
    <div ref={wrapperRef}>
      <Nav>
        {!pinned && (
          <BarsContainer
            className="bars-container"
            searchClicked={searchClicked}
          >
            <div className="icon-container">
              <FaIcons.FaBars onClick={showSidebar} />
            </div>
          </BarsContainer>
        )}

        <SearchContainer
          ref={searchRef}
          pinned={pinned}
          searchClicked={searchClicked}
          onClick={() => {
            setSearchClicked(true);
          }}
        >
          <Search />
        </SearchContainer>
        {width > breakPoint && !pinned && !searchClicked && (
          <StyledBrandContainer name="AARA Hub" />
        )}
        <LogoutContainer
          className="logout-container"
          pinned={pinned}
          searchClicked={searchClicked}
        >
          <div className="icon-container">
            <SignOutIcon />
          </div>
        </LogoutContainer>
      </Nav>

      <SidebarNav className="sideBar-nav" sidebar={sidebar}>
        <div className="sideBar-wrap">
          {pinned ? (
            <StyledBrandContainer name="AARA Hub" />
          ) : (
            !pinnable && <StyledBrandContainer name="AARA Hub" />
          )}
          {pinnable && (
            <div className="nav-main-icons" to="#">
              {pinned ? (
                <div className="icon-container">
                  <AiIcons.AiOutlineClose onClick={handlePin} />
                </div>
              ) : (
                <div className="icon-container">
                  <AiIcons.AiFillPushpin onClick={handlePin} />
                </div>
              )}
            </div>
          )}

          <div style={{ height: "80%", overflow: "auto" }}>
            {SidebarData.map((item, index) => {
              return (
                <SubMenu
                  pinned={pinned}
                  setPinned={setPinned}
                  showSidebar={showSidebar}
                  item={item}
                  key={index}
                />
              );
            })}

            {/* <NccdMenu
              pinned={pinned}
              setPinned={setPinned}
              showSidebar={showSidebar}
            /> */}
            <ExamMenu
              pinned={pinned}
              setPinned={setPinned}
              showSidebar={showSidebar}
            />
            {privileges === 1 && (
              <AdminMenu
                pinned={pinned}
                setPinned={setPinned}
                showSidebar={showSidebar}
              />
            )}
            {/* <NccdMenu
              pinned={pinned}
              setPinned={setPinned}
              showSidebar={showSidebar}
            /> */}
          </div>
        </div>
      </SidebarNav>
    </div>
  );
};

export default Sidebar;
