import React from "react";

import { Card } from "react-bootstrap";

import styled from "styled-components";
import { SignInButton } from "./SignInButton";

const MyContainer = styled.div`
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const MyCard = styled(Card)`
  width: 18rem;
  /* height: 10rem; */
  margin-top: -80px;
`;

const WelcomeMessage = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Raleway", sans-serif;
  font-size: 3rem;
  /* position: absolute; */
  /* border:1px solid black; */
  width: 100%;
`;

const SignIn = () => {
  return (
    <MyContainer>
      <MyCard style={{ width: "40rem" }}>
        <Card.Body>
          <div style={{ width: "100%", position: "relative" }}>
            <WelcomeMessage>AARA Hub</WelcomeMessage>
            <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "center",
              }}
            >
              <img height={200} src={process.env.PUBLIC_URL + "/Geodesic.png"} alt="" />
            </div>
          </div>
        </Card.Body>
        <Card.Footer>
          <div
            style={{ width: "100%", display: "flex", justifyContent: "center" }}
          >
            <SignInButton />
          </div>
        </Card.Footer>
      </MyCard>
    </MyContainer>
  );
};

export default SignIn;
