/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-useless-escape */
import React, { useReducer, useContext, useEffect } from "react";

import NccdContext from "./nccdContext";
import nccdReducer from "./nccdReducer";
import appContext from "../../../../context/app/appContext";

import moment from "moment";

// import { serverUrl } from "../../../../authConfig";
import {
  CHANGE_STATE,
  SHIFT_MONTH,


  GET_LESSONS,
  SET_MONTH,
} from "./types";

// import { msalConfig } from "../../../../authConfig";

// import axios from "axios";

export const NccdState = (props) => {


  const app_context = useContext(appContext);

  const {  dbGet, dbUser } = app_context;

  const windowStart =
    moment().startOf("month").startOf("week").format("DD") === "01"
      ? moment()
          .startOf("month")
          .startOf("week")
          .subtract(6, "days")
          .format("YYYY-MM-DD")
      : moment()
          .startOf("month")
          .startOf("week")
          .add(1, "days")
          .format("YYYY-MM-DD");

  const windowEnd =
    moment().endOf("month").format("DDD") === "Sun"
      ? moment().endOf("month").format("YYYY-MM-DD")
      : moment()
          .endOf("month")
          .endOf("week")
          .add(1, "days")
          .format("YYYY-MM-DD");

  const initialState = {
    yearOptions: [],
    selectedYear: "",
    nccdDays: [],
    selectedMonth: {
      value: moment().startOf("month").format(),
      mthString: moment().startOf("month").format("MMMM, YYYY"),
      windowStart: windowStart,
      windowEnd: windowEnd,
    },
    selectedDay: {
      year: "",
      week: "",
      day: "",
    },
    formPostion: 0,
    grid: [],
    displayedClasses: [],
    evidence: [],
    displayedLessons: [],
    lessonsFetched: false,
    selectedStudents:{
      idArray:[],
      studentArray:[]
    },
    allMyClassStudents:[]
  };

  const [state, dispatch] = useReducer(nccdReducer, initialState);

  // const sortByProperty = async (data, sortByColumn) => {
  //   var sorted = data.reduce((r, a, i) => {
  //     if (!i || r[r.length - 1][0][sortByColumn] !== a[sortByColumn]) {
  //       return r.concat([[a]]);
  //     }
  //     r[r.length - 1].push(a);
  //     return r;
  //   }, []);

  //   return sorted;
  // };

  const changeNccdState = (property, value) => {
    dispatch({
      type: CHANGE_STATE,
      payload: {
        property: property,
        value: value,
      },
    });
  };

  const shiftMonth = (direction) => {
    dispatch({
      type: SHIFT_MONTH,
      payload: direction,
    });
  };



  const getLessonsInRange = async () => {
    const res = await dbGet({
      type: "getLessonsInRange",
      windowStart: state.nccdDays[0].NccdDate,
      windowEnd: moment(state.nccdDays[state.nccdDays.length-1].NccdDate, "YYYY-MM-DD")
        .add(1, "days")
        .format("YYYY-MM-DD"),
      staffId: dbUser.StaffId,
    });
    const formatted = res.map((lesson) => {
      return {
        ...lesson,
        LessonDate: moment(lesson.LessonDate).format("YYYY-MM-DD"),
      };
    });
    // console.log("lessons", formatted);
    dispatch({ type: GET_LESSONS, payload: formatted });
  };

  const setMonth = (value) => {
    console.log("hitting set month", value);
    dispatch({
      type: SET_MONTH,
      payload: value,
    });
  };

  useEffect(() => {
    if (state.nccdDays.length > 0 && dbUser) {
      getLessonsInRange();
    }
  }, [state.nccdDays,dbUser]);

  return (
    <NccdContext.Provider
      value={{
        selectedMonth: state.selectedMonth,
        selectedDay: state.selectedDay,
        formPosition: state.formPosition,
        grid: state.grid,
        displayedClasses: state.displayedClasses,
        evidence: state.evidence,
        selectedYear: state.selectedYear,
        yearOptions: state.yearOptions,
        nccdDays: state.nccdDays,
        displayedLessons: state.displayedLessons,
        lessonsFetched: state.lessonsFetched,
        selectedStudents:state.selectedStudents,
        allMyClassStudents:state.allMyClassStudents,
        changeNccdState,
        shiftMonth,
        setMonth,
      }}
    >
      {props.children}
    </NccdContext.Provider>
  );
};
