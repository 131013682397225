import React, { useReducer, useContext } from "react";

import AaraContext from "./aaraContext";
import aaraReducer from "./aaraReducer";
import appContext from "../../../../context/app/appContext";

import { CHANGE_STATE, HANDLE_DELETE } from "./types";

export const AaraState = (props) => {
  const app_context = useContext(appContext);
  const { dbGet, userInfo, dbUser, changeAppState } = app_context;

  const initialState = {
    myStudents: [],
    allStudents: [],
    searchedStudents: [],
    caseManagedStudents: [],
    searchType: "",
    selectedSearchOption: "",
  };

  const [state, dispatch] = useReducer(aaraReducer, initialState);

  const updateAaraStudents = async () => {
    const my = await dbGet({
      type: "getMyAaraStudents",
      emailAddress: userInfo.emailAddress,
      sortBy: "ClassId",
    });
    changeAaraState("myStudents", my);

    const all = await dbGet({
      type: "getAllAaraStudents",

      sortBy: "YearLevel",
    });
    changeAaraState("allStudents", all);

    const caseManaged = await dbGet({
      type: "getMyCaseManagedStudents",
      staffId: dbUser.StaffId,
      sortBy: "YearLevel",
    });
    changeAaraState("caseManagedStudents", caseManaged);
    changeAaraState("searchedStudents", []);
    changeAppState("staffTypeChecked", false);
  };

  const changeAaraState = (property, value) => {
    dispatch({
      type: CHANGE_STATE,
      payload: {
        property: property,
        value: value,
      },
    });
  };

  const handleDelete = () => {
    dispatch({
      type: HANDLE_DELETE,
    });
  };

  return (
    <AaraContext.Provider
      value={{
        myStudents: state.myStudents,
        allStudents: state.allStudents,
        searchType: state.searchType,
        caseManagedStudents: state.caseManagedStudents,
        selectedSearchOption: state.selectedSearchOption,
        searchedStudents: state.searchedStudents,
        changeAaraState,
        updateAaraStudents,
        handleDelete,
      }}
    >
      {props.children}
    </AaraContext.Provider>
  );
};
