import React from "react";

import { Button } from "react-bootstrap";

const StyledButton = (props) => {
  const { text, func, component } = props;
  return (
    <Button className="my-style" onClick={func}>
      {text} {component}
    </Button>
  );
};

export default StyledButton;
