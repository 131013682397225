import React, { useReducer } from "react";


import FileContext from "./fileContext";
import fileReducer from './fileReducer'
// import appContext from "../../../../../../../../../context/app/appContext";

import { CHANGE_STATE} from "./types";

export const FileState = (props) => {
  // const app_context = useContext(appContext);
  // const { dbGet } = app_context;

  const initialState = {
    fileList:[]
  };

  const [state, dispatch] = useReducer(fileReducer, initialState);

 

  

  const changeFileState = (property, value) => {
    dispatch({
      type: CHANGE_STATE,
      payload: {
        property: property,
        value: value,
      },
    });
  };

  return (
    <FileContext.Provider
      value={{
        fileList:state.fileList,
        changeFileState
      }}
    >
      {props.children}
    </FileContext.Provider>
  );
};
