import { CHANGE_STATE,HANDLE_DELETE} from "./types";

const reducerFunction = (state, action) => {
  switch (action.type) {
    case CHANGE_STATE:
      return{
        ...state,
        [action.payload.property]:action.payload.value
      }
    case HANDLE_DELETE:

    

      return{
        ...state,
        myStudents: [],
        allStudents:[],
        searchedStudents:[],
        caseManagedStudents:[]
      }
    
    
    default:
      return state;
  }
};

export default reducerFunction;
