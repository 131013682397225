/* eslint-disable no-redeclare */
import {
  CHANGE_STATE,
  UPDATE_RECIPIENTS,
  CLEAR_MESSAGE,
  EMAIL_TEMPLATE,
} from "./types";

const uniq = (a) => [...new Set(a)];

function getUniqueListBy(arr, key) {
  return [...new Map(arr.map((item) => [item[key], item])).values()];
}

const reducerFunction = (state, action) => {
  var { recipientType, selectOptions, bodyContent, subject } = action.payload;

  switch (action.type) {
    case CLEAR_MESSAGE:
      return {
        ...state,
        message: state.blankMessage,
        toRecipients: [],
        ccRecipients: [],
        bccRecipients: [],
        sendState: "writing",
      };
    case CHANGE_STATE:
      return {
        ...state,
        [action.payload.property]: action.payload.value,
      };

    case UPDATE_RECIPIENTS:
      var addressArray = selectOptions.map((option) =>
        option.emailAddress ? option.emailAddress : option.value
      );

      addressArray = addressArray.filter((email) => {
        if (email.includes("onmicrosoft") || email.includes("example.com")) {
          return null;
        } else {
          return email;
        }
      });

      addressArray = uniq(addressArray);
      return {
        ...state,
        message: {
          ...state.message,
          [recipientType]: addressArray.map((entry) => {
            return {
              emailAddress: {
                address: entry,
              },
            };
          }),
        },
        [recipientType]: getUniqueListBy(selectOptions, "value"),
      };

    case EMAIL_TEMPLATE:
      // console.log(action.payload)
      var addressArray = selectOptions.map((option) =>
        option.emailAddress ? option.emailAddress : option.value
      );
      addressArray = selectOptions.map((option) =>
        option.emailAddress ? option.emailAddress : option.value
      );

      addressArray = addressArray.filter((email) => {
        if (email.includes("onmicrosoft") || email.includes("example.com")) {
          return null;
        } else {
          return email;
        }
      });

      addressArray = uniq(addressArray);
      return {
        ...state,
        showEmailModal: true,
        message: {
          ...state.message,
          subject: subject,
          [recipientType]: addressArray.map((entry) => {
            return {
              emailAddress: {
                address: entry,
              },
            };
          }),
          body: { ...state.message.body, content: bodyContent },
        },
        [recipientType]: getUniqueListBy(selectOptions, "value"),
      };

    default:
      return state;
  }
};

export default reducerFunction;
