import React from "react";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../../../authConfig";

import { AiFillWindows } from "react-icons/ai";
import StyledButton from "../../global/StyledButton";

export const SignInButton = () => {
  const { instance } = useMsal();

  const handleLogin = () => {
    instance.loginRedirect(loginRequest).catch((e) => {
      console.log(e);
    });
  };

  return (
    <StyledButton
      text="Sign in"
      func={handleLogin}
      component={<AiFillWindows />}
    />
  );
};
