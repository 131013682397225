import {
  CHANGE_STATE,
  SETUP_EXAMS,
  BLANK_EXAM,
  BOOK_ALL_RECOMMENDED,
  BOOK_ALL,
  CHANGE_STUDENT_SEATING,
  CHANGE_BOOKING_NOTES,
  GET_RETURNED_EXAM,
} from "./types";

const reducerFunction = (state, action) => {
  switch (action.type) {
    case GET_RETURNED_EXAM:
      return {
        ...state,
        selectedExam: action.payload,
        oldSelectedExam: action.payload,
        fileArray: [],
      };
    case BLANK_EXAM:
      return {
        ...state,
        selectedExam: state.blankExam,
        oldSelectedExam: state.blankExam,
        subjectStudents: [],
        oldSubjectStudents: [],
        exitMode: false,
        infoIsDefault: true,
      };
    case CHANGE_STATE:
      return {
        ...state,
        [action.payload.property]: action.payload.value,
      };

    case SETUP_EXAMS:
      return {
        ...state,
        examAzureGroup: action.payload.examAzureGroup,
        examAdmins: action.payload.examAdmins,
        mailList: action.payload.mailList,
        mailListAzureGroup: action.payload.mailListAzureGroup,
      };

    case BOOK_ALL_RECOMMENDED:
      var oldBookings = state.subjectStudents.slice();

      var newBookings = oldBookings.map((group) =>
        group.map((student) => {
          return {
            ...student,
            seating: student.recommendedSeating,
          };
        })
      );
      return {
        ...state,
        subjectStudents: newBookings,
      };

    case BOOK_ALL:
      oldBookings = state.subjectStudents.slice();

      newBookings = oldBookings.map((group) =>
        group.map((student) => {
          return {
            ...student,
            seating: action.payload,
          };
        })
      );
      return {
        ...state,
        subjectStudents: newBookings,
      };

    case CHANGE_STUDENT_SEATING:
      var studentId = action.payload.studentId;
      var seatingValue = action.payload.seatingValue;

      oldBookings = state.subjectStudents.slice();

      newBookings = oldBookings.map((group) =>
        group.map((student) => {
          if (student.StudentId === studentId) {
            return {
              ...student,
              seating: seatingValue,
            };
          } else {
            return student;
          }
        })
      );
      return {
        ...state,
        subjectStudents: newBookings,
      };

    case CHANGE_BOOKING_NOTES:
      studentId = action.payload.studentId;
      var notes = action.payload.notes;

      oldBookings = state.subjectStudents.slice();

      newBookings = oldBookings.map((group) =>
        group.map((student) => {
          if (student.StudentId === studentId) {
            return {
              ...student,
              notes: notes,
            };
          } else {
            return student;
          }
        })
      );
      return {
        ...state,
        subjectStudents: newBookings,
      };

    default:
      return state;
  }
};

export default reducerFunction;
