import React, { useReducer } from "react";


import MedicalContext from './medicalContext'
import medicalReducer from './medicalReducer'
// import appContext from "../../../../../../../../../context/app/appContext";

import { CHANGE_STATE} from "./types";

export const MedicalState = (props) => {
  // const app_context = useContext(appContext);
  // const { dbGet } = app_context;

  const initialState = {
    medicalList: [],
    diagnosisEdit: {
      Diagnosis: "",
      TestType: "",
      DiagnosisDate: "",
      Clinician: "",
      ContainerName: "",
      BlobName: "",
      Summary: "",
    },
  };

  const [state, dispatch] = useReducer(medicalReducer, initialState);

 

  

  const changeMedicalState = (property, value) => {
    dispatch({
      type: CHANGE_STATE,
      payload: {
        property: property,
        value: value,
      },
    });
  };

  return (
    <MedicalContext.Provider
      value={{
        medicalList:state.medicalList,
        diagnosisEdit:state.diagnosisEdit,
        changeMedicalState
      }}
    >
      {props.children}
    </MedicalContext.Provider>
  );
};
