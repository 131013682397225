/* eslint-disable default-case */
// import { LogLevel } from "@azure/msal-browser";

/**
 * Configuration object to be passed to MSAL instance on creation.
 * For a full list of MSAL.js configuration parameters, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/configuration.md
 */
export const msalConfig = {
  auth: {
    clientId: "c34ffe28-c960-4ce7-9eab-0278a7e2af44",
    authority: "https://login.microsoftonline.com/geodesic-solutions.com",
    redirectUri: "https://aarahub.geodesic-solutions.com",
    // redirectUri: "http://localhost:3000",
  },
  cache: {
    cacheLocation: "sessionStorage", // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
  system: {
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return;
        }
        // switch (level) {
        //   case LogLevel.Error:
        //     console.error(message);
        //     return;
        //   case LogLevel.Info:
        //     console.info(message);
        //     return;
        //   case LogLevel.Verbose:
        //     console.debug(message);
        //     return;
        //   case LogLevel.Warning:
        //     console.warn(message);
        //     return;
        // }
      },
    },
  },
};

export const loginRequest = {
  scopes: [
    "User.Read",
    "User.Read.All",
    "Group.Read.All",
    "GroupMember.Read.All",
  ],
};

export const storageRequest = {
  scopes: ["https://storage.azure.com/user_impersonation"],
};

export const dbRequest = {
  scopes: ["https://database.windows.net/user_impersonation"],
};

export const pbiRequest = {
  scopes: [
    "https://analysis.windows.net/powerbi/api/Report.Read.All",
    "https://analysis.windows.net/powerbi/api/Report.ReadWrite.All",
  ],
};

export const protectedRequest = {
  scopes: ["api://c34ffe28-c960-4ce7-9eab-0278a7e2af44/access_as_user"],
};

//-------SERVER URLS

export const serverUrl = "https://aarahubgeodesic.azurewebsites.net/";

// export const serverUrl = "http://localhost:4000";

export const studentEndpoint = "https://geodesicaarahubstudents.blob.core.windows.net";

export const storageEndpoint = "https://geodesicaarahub.blob.core.windows.net";

export const graphEndpoint = "https://graph.microsoft.com/v1.0/me";

export const studentFileAccount = "geodesicaarahubstudents";

export const mainStorageAccount = "geodesicaarahub";
