/* eslint-disable no-unused-vars */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import styled from "styled-components";
import { BsChevronUp, BsChevronDown } from "react-icons/bs";
import { Collapse } from "react-bootstrap";
import { SquareLoader } from "react-spinners";
import SubMenu from "../SubMenu";

const SidebarLabel = styled.div`
  display: flex;
  gap: 0.6rem;
  font-family:'Raleway', sans-serif;
`;

const SidebarChild = styled.div`
  /* flex-grow: 1; */
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

const ArrowContainer = styled.div`
  width: 100%;
  margin-left: 20px;
  display: flex;
  justify-content: flex-end;
  margin-top: -5px;
`;

const Expandable = (props) => {
  const { icon, title, subArray, pinned, setPinned, showSidebar } = props;

  const [subnav, setSubnav] = useState(false);

  const [loading, setLoading] = useState(false);

  const showSubnav = () => {
    setSubnav(!subnav);
  };

  return (
    <>
      <SidebarLabel onClick={showSubnav} className="email-link">
        <SidebarChild>{icon}</SidebarChild>
        <SidebarChild>{title}</SidebarChild>
        <ArrowContainer>
          {loading ? (
            <SquareLoader size={15} color="white" />
          ) : subnav ? (
            <BsChevronUp />
          ) : (
            <BsChevronDown />
          )}
        </ArrowContainer>
      </SidebarLabel>

      <Collapse in={subnav}>
      <div>
        {subArray.map((item, index) => {
          return (
            
            <SubMenu
              pinned={pinned}
              setPinned={setPinned}
              showSidebar={showSidebar}
              item={item}
              key={index}
            />
          );
        })}
      </div>
      </Collapse>
    </>
  );
};

export default Expandable;
