import {
  LOGIN_SUCCESS,
  CHANGE_STATE,
  CHECK_CLASSES,
  CHECK_STAFF_TYPE,
} from "./types";

const reducerFunction = (state, action) => {
  switch (action.type) {
    case CHANGE_STATE:
      return {
        ...state,
        [action.payload.category]: action.payload.value,
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        userInfo: action.payload,
      };
    case CHECK_CLASSES:
      return {
        ...state,
        userClasses: action.payload,
        classesChecked: true,
      };
    case CHECK_STAFF_TYPE:
      const { isCaseManager, isTeacher } = action.payload;

      return {
        ...state,
        staffTypeChecked: true,
        dbUser: {
          ...state.dbUser,
          IsCaseManager: isCaseManager,
          IsTeacher: isTeacher,
        },
      };

    default:
      return state;
  }
};

export default reducerFunction;
