export const customStyles = {
  input: (provided, state) => ({
    ...provided,
    height: "100%",
    backgroundColor: state.isFocused ? "#fceaba" : "none",
  }),
  control: (provided, state) => ({
    ...provided,
    backgroundColor: "white",
    border: "1px solid grey",
    "&:hover": {
      backgroundColor: !state.isDisabled && "rgb(217, 200, 188)",
    },
  }),
  container: (provided, state) => ({
    ...provided,
  }),
  option: (provided, state) => ({
    ...provided,

    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    backgroundColor: state.isFocused ? "rgb(217, 200, 188)" : "white",
    border: state.isFocused && "1px solid rgb(85, 38, 19)",
    color: "black",
  }),
  singleValue: (provided, state) => ({
    ...provided,
    color: "black",
  }),
  groupHeading: (provided, state) => ({
    ...provided,
    backgroundColor: "#e3dfde",
    // fontFamily: "Caveat, Sans-Serif",
    fontSize: "1.3rem",
    color: "black",
  }),
  menu: (provided, state) => ({
    ...provided,
    borderRadius: "0px",
    padding: "0px",
    zIndex: "1000",
  }),
};

export const customStylesLarge = {
  control: () => ({
    display: "flex",
    height: "70px",
    cursor: "pointer",
  }),
  dropdownIndicator: () => ({
    display: "none",
  }),
  indicatorSeparator: () => ({
    display: "none",
  }),
  input: (provided, state) => ({
    ...provided,
    height: "100%",
  }),
  option: (provided, state) => ({
    ...provided,
    height: "70px",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    backgroundColor: state.isFocused ? "rgb(217, 200, 188)" : "white",
    border: state.isFocused && "1px solid rgb(85, 38, 19)",
    color: "black",
  }),
  noOptionsMessage: (provided, state) => ({
    ...provided,
    height: "70px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  }),
  menu: (provided, state) => ({
    ...provided,
    borderRadius: "0px",
    zIndex: "1000",
  }),
};
