import React from "react";
import { useMsal } from "@azure/msal-react";
import { MdLogout } from "react-icons/md";

/**
 * Renders a sign-out button
 */
export const SignOutIcon = () => {
  const { instance } = useMsal();

  const handleLogout = (logoutType) => {
    instance.logoutRedirect({
      postLogoutRedirectUri: "/",
    });
  };

  return <MdLogout onClick={handleLogout} />;
};
