import { IoSchoolOutline } from "react-icons/io5";
import {BsClipboardData} from 'react-icons/bs'

export const SidebarData = [
  {
    title: "Students",
    path: "/students",
    icon: <IoSchoolOutline />,
    accessLevel: 0,
  },
  {
    title: "Data",
    path: "/data",
    icon: <BsClipboardData/>,
    accessLevel: 1,
  },
];
