import React from "react";
import Expandable from "../Expandable";
import { IoSettings } from "react-icons/io5";
import { AiOutlineControl, AiOutlineHistory } from "react-icons/ai";

const AdminMenu = (props) => {
  return (
    <Expandable
      {...props}
      icon={<AiOutlineControl />}
      title="Admin"
      subArray={[
        {
          title: "Settings",
          icon: <IoSettings />,
          path: "/admin",
          accessLevel: 1,
          padLeft: "20px",
        },
        {
          title: "History",
          icon: <AiOutlineHistory />,
          path: "/history",
          accessLevel: 1,
          padLeft: "20px",
        },
      ]}
    />
  );
};

export default AdminMenu;
