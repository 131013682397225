import { CHANGE_STATE } from "./types";

const reducerFunction = (state, action) => {
  switch (action.type) {
    case CHANGE_STATE:
      return{
        ...state,
        [action.payload.property]:action.payload.value
      }
    
    default:
      return state;
  }
};

export default reducerFunction;
