import React, { useReducer } from "react";

import StudentContext from "./studentContext";
import studentReducer from "./studentReducer";
import { CHANGE_STATE, BLANK_NCCD, RESET_NCCD, NEW_TO_OLD } from "./types";

export const StudentState = (props) => {
  const initialState = {
    selectedStudent: "",
    selectedNccd: "",
    oldSelectedNccd:"",
    selectedNccdTab: 0,
    canSubmit: false,
    nccdFormEdited: false,
    nccdCategoryOptions: [],
    nccdSupportLevelOptions: [],
    nccdCaseManagerOptions: [],
    validToDate: null,
    caseManager: null,
    supportLevel: null,
    category: null,
    oldValidToDate: null,
    oldCategory: null,
    oldSupportLevel: null,
    oldCaseManager: null,
    nccdUpdated: null,
    profileSaved:false,
    canReset:false,
   
    dateSame:true,
    categorySame:true,
    supportLevelSame:true,
    caseManagerSame:true,

  };

  const [state, dispatch] = useReducer(studentReducer, initialState);

  const changeStudentState = (property, value) => {
    dispatch({
      type: CHANGE_STATE,
      payload: {
        property: property,
        value: value,
      },
    });
  };

  const setBlankNccd = () => {
    dispatch({
      type: BLANK_NCCD,
    });
  };

  const resetNccd = () => {
    dispatch({
      type: RESET_NCCD,
    });
  };

  const newToOld = () => {
    dispatch({
      type: NEW_TO_OLD,
    });
  };

  return (
    <StudentContext.Provider
      value={{
        selectedStudent: state.selectedStudent,
        selectedNccd: state.selectedNccd,
        oldSelectedNccd:state.oldSelectedNccd,
        selectedNccdTab: state.selectedNccdTab,
        canSubmit: state.canSubmit,
        nccdUpdated: state.nccdUpdated,
        nccdFormEdited: state.nccdFormEdited,
        nccdCategoryOptions: state.nccdCategoryOptions,
        nccdSupportLevelOptions: state.nccdSupportLevelOptions,
        nccdCaseManagerOptions: state.nccdCaseManagerOptions,
        validToDate: state.validToDate,
        caseManager: state.caseManager,
        supportLevel: state.supportLevel,
        category: state.category,
        oldValidToDate: state.oldValidToDate,
        oldCaseManager: state.oldCaseManager,
        oldSupportLevel: state.oldSupportLevel,
        oldCategory: state.oldCategory,
        profileSaved:state.profileSaved,
        canReset:state.canReset,
        dateSame: state.dateSame,
        categorySame: state.categorySame,
        supportLevelSame: state.supportLevelSame,
        caseManagerSame: state.caseManagerSame,
        changeStudentState,
        setBlankNccd,
        resetNccd,
        newToOld,
      }}
    >
      {props.children}
    </StudentContext.Provider>
  );
};
