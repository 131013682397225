import React, { useState, useContext} from "react";
import Select from "react-select";
import styled from "styled-components";
// import { get } from "../../api/get";
import AppContext from "../../context/app/appContext";
import { useDebounce } from "../../hooks/useDebounce";
import { useHistory } from "react-router-dom";
import { SquareLoader } from "react-spinners";
import { customStylesLarge } from "../global/selectStyles";
import PhotoBox from '../global/PhotoBox'
import LayoutContext from "../../context/layout/layoutContext";


const SearchBarContainer = styled.div`
  width: 100%;
  /* background-color:blue */
`;

const OptionContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

const OptionItem = styled.div`
  flex-grow: 1;
  display:flex;
  justify-content: center;
  align-items: center;
  width: 25%;
  /* border:1px solid black; */
  
`;





const Search = () => {

  const layoutContext=useContext(LayoutContext)

  const {isDesktop}=layoutContext


  const appContext = useContext(AppContext);
  const { dbGet } = appContext;

  const [searchTerm, setSearchTerm] = useState("");
  const [searching, setSearching] = useState(false);
  const [studentOptions, setStudentOptions] = useState([]);

  

  const history = useHistory();

  const searchStudents = async () => {
    const res = await dbGet({
      type: 'searchStudent',
      searchTerm:searchTerm
    })

    // console.log(res)

    const options = res.map((student) => {
      const { FirstName, Surname, YearLevel, House,MisId} = student;

      return {
        label: `${FirstName} ${Surname} (Year ${YearLevel} - ${House}, ${MisId})`,
        value: student,
      };
    });
    setStudentOptions(options);
    setSearching(false);
  };

  useDebounce(searchTerm, 250, searchStudents);
  return (
    <SearchBarContainer>
      <Select
        noOptionsMessage={() => {
          if (searching) {
            return <SquareLoader size={20} color="grey" />;
          } else {
            return "No students to display...";
          }
        }}
        placeholder="Search students..."
        styles={customStylesLarge}
        options={studentOptions}
        value={null}
        onInputChange={(e) => {
          if (e) {
            setSearching(true);
            setSearchTerm(e);
          } else {
            setSearching(false);
          }
        }}
        onChange={(e) => {
          history.push(`/profile/${e.value.StudentId}`);
        }}
        formatOptionLabel={(option) => (
          <OptionContainer>
            <OptionItem>
              <div>
                <b>
                  {option.value.FirstName} {option.value.Surname}
                </b>
              </div>
            </OptionItem>
            {isDesktop && (
              <OptionItem>Year {option.value.YearLevel}</OptionItem>
            )}

            <OptionItem>{option.value.House}</OptionItem>
            {isDesktop && (
              <OptionItem>
                <PhotoBox
                  height={60}
                  id={option.value.StudentId}
                  container="student-photos"
                />
              </OptionItem>
            )}
          </OptionContainer>
        )}
      />
    </SearchBarContainer>
  );
};

export default Search;
