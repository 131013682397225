import React, { useReducer } from "react";

import LayoutContext from "./layoutContext";
import layoutReducer from "./layoutReducer";

// import { useHistory } from "react-router-dom";
import { CHANGE_STATE } from "./types";
// import { functionUrl } from "../../authConfig";

const breakPoint = 1110;

export const LayoutState = (props) => {
  // const history = useHistory();

  const initialState = {
    isDesktop: window.innerWidth < breakPoint ? false : true,
    desktopBreakpoint: breakPoint
  };

  const [state, dispatch] = useReducer(layoutReducer, initialState);

  const changeLayoutState = (property, value) => {
    dispatch({
      type: CHANGE_STATE,
      payload: {
        property: property,
        value: value,
      },
    });
  };

  return (
    <LayoutContext.Provider
      value={{
        isDesktop: state.isDesktop,
        desktopBreakpoint: state.desktopBreakpoint,
        changeLayoutState,
      }}
    >
      {props.children}
    </LayoutContext.Provider>
  );
};
