/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useContext, useState,Suspense,lazy } from "react";
import AppContext from "./context/app/appContext";
import NavRoutes from "./NavRoutes";
import Sidebar from "./components/sidebar/Sidebar";
import LayoutContext from "./context/layout/layoutContext";
import { useMsal } from "@azure/msal-react";
import LoadComponent from "./components/global/LoadComponent";
const Email = lazy(()=>import('./components/email/Email'))

const AppContainer = () => {
  const { instance } = useMsal();
  const layoutContext = useContext(LayoutContext);

  const { changeLayoutState, desktopBreakpoint } = layoutContext;

  const appContext = useContext(AppContext);

  const { RequestAccessToken, dbGet, changeAppState,staffTypeChecked,dbUser,changeStaffType } = appContext;

  useEffect(() => {
    if (dbUser && !staffTypeChecked) {
      runChecks();
    }
  }, [dbUser, staffTypeChecked]);

  const runChecks = async () => {
    const res = await dbGet({
      type: "checkStaffTypes",
      staffId: dbUser.StaffId,
      isMulti: true,
    });

    // console.log("is teacher", res[0].length > 0);
    // console.log("is case manager", res[1].length > 0);

    var isTeacher = res[1].length > 0;
    var isCaseManager = res[0].length > 0;

    changeStaffType(isTeacher, isCaseManager);
    
  };

  const handleResize = () => {
    setContainerHeight(window.innerHeight - 70);
    if (window.outerWidth > desktopBreakpoint) {
      changeLayoutState("isDesktop", true);
    } else {
      changeLayoutState("isDesktop", false);
    }
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    window.addEventListener("orientationchange", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
      window.removeEventListener("orientationchange", handleResize);
    };
  });

  const [pinned, setPinned] = useState(() => {
    return window.innerWidth < desktopBreakpoint ? false : true;
  });
  const [margin, setMargin] = useState("250");
  const [pinnable, setPinnable] = useState(() => {
    return window.innerWidth < desktopBreakpoint ? false : true;
  });

  useEffect(() => {
    pinned ? setMargin("280px") : setMargin("0");
  }, [pinned]);

  const checkGroups = (accessGroups, userGroups) => {
    if (userGroups.includes(accessGroups[1].AzureGroupId)) {
      changeAppState("privileges", 1);
    } else if (userGroups.includes(accessGroups[0].AzureGroupId)) {
      changeAppState("privileges", 0);
    } else {
      instance.logoutRedirect({
        postLogoutRedirectUri: "/",
      });
    }
  };

  const checkPermissions = async () => {
    const res = await RequestAccessToken("graph");

    const userGroups = res.idTokenClaims.groups;

    const accessGroups = await dbGet({ type: "getAccessGroups" });

     const res2 = await dbGet({
      type: "checkUser",
      email: res.account.username,
    });
    if (res2.length > 0) {
      changeAppState("dbUser", {
        ...res2[0],
        CreatedBy: `${res2[0].Surname}, ${res2[0].FirstName} (${res2[0].StaffEmail})`,
      });
    } else {
      changeAppState("dbUser", {
        FirstName: "Test User (Dev)",
        CreatedBy: `Test User (Dev)`,
      });
    }

    checkGroups(accessGroups, userGroups);
  };



  useEffect(() => {
    const runEffect = async () => {
      await checkPermissions();
    };
    runEffect();
  }, []);

  const [containerHeight, setContainerHeight] = useState(
    window.innerHeight - 70
  );

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    window.addEventListener("orientationchange", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
      window.removeEventListener("orientationchange", handleResize);
    };
  });

  return (
    <>
      <Sidebar
        pinnable={pinnable}
        setPinnable={setPinnable}
        setPinned={setPinned}
        pinned={pinned}
        setMargin={setMargin}
      />
      <div
        // className="main-container"
        style={{
          marginLeft: `${margin}`,
          height: `${containerHeight}px`,
          overflow: "auto",
          display:'flex',
          flexDirection:'column'
        }}
      >
        <NavRoutes />
        <Suspense fallback={<LoadComponent />}>
          
          <Email />
        </Suspense>
      </div>
    </>
  );
};

export default AppContainer;
