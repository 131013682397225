import { CHANGE_STATE, SETUP_QCAA, CLEAR_ALL_AARA } from "./types";

const reducerFunction = (state, action) => {
  switch (action.type) {
    case CHANGE_STATE:
      return {
        ...state,
        [action.payload.property]: action.payload.value,
      };

    case SETUP_QCAA:
      return {
        ...state,
        qcaaByAdjustment: action.payload.qcaaByAdjustment,
        oldQcaaByAdjustment: action.payload.qcaaByAdjustment,
        qcaaBySubject: action.payload.qcaaBySubject,
        oldQcaaBySubject: action.payload.qcaaBySubject,
        studiedSubjects: action.payload.studiedSubjects,
        possibleQcaaAdjustments: action.payload.possibleAdjustments,
        adjustmentSelectOptions: action.payload.adjustmentSelectOptions,
        subjectSelectOptions: action.payload.subjectSelectOptions,
      };

    case CLEAR_ALL_AARA:
      return {
        ...state,
        studentProvisions: [],
        oldStudentProvisions: [],
        qcaaByAdjustment: [],
        oldQcaaByAdjustment: [],
        qcaaBySubject: [],
        oldQcaaBySubject: [],
      };

    default:
      return state;
  }
};

export default reducerFunction;
