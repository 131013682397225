import React from "react";
import { SquareLoader } from "react-spinners";
import styled from "styled-components";

const LoadContainer = styled.div`
  display: flex;
  height: 100px;
  align-items: center;
  justify-content: center;
  width:100%;
`;

const LoadComponent = () => {
  return (
    <LoadContainer>
      <SquareLoader size={20} color="grey"/>
    </LoadContainer>
  );
};

export default LoadComponent;
