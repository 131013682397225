/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useReducer } from "react";

import EmailContext from "./emailContext";
import emailReducer from "./emailReducer";
import appContext from "../../../context/app/appContext";

import { CHANGE_STATE, CLEAR_MESSAGE, UPDATE_RECIPIENTS,EMAIL_TEMPLATE } from "./types";
import axios from "axios";
import { serverUrl } from "../../../authConfig";

export const EmailState = (props) => {
  const app_context = useContext(appContext);

  const { RequestAccessToken } = app_context;

  const initialState = {
    showEmailModal: false,
    blankMessage: {
      subject: "",
      toRecipients: [],
      ccRecipients: [],
      bccRecipients: [],
      body: {
        contentType: "",
        content: "",
      },
      attachments: [],
    },
    message: {
      subject: "",
      toRecipients: [],
      ccRecipients: [],
      bccRecipients: [],
      body: {
        contentType: "",
        content: "",
      },
      attachments: [],
    },
    toRecipients: [],
    ccRecipients: [],
    bccRecipients: [],
    toOpen: true,
    ccOpen: false,
    bccOpen: false,
    sendState: "writing",
  };

  const [state, dispatch] = useReducer(emailReducer, initialState);

  const changeEmailState = (property, value) => {
    dispatch({
      type: CHANGE_STATE,
      payload: {
        property: property,
        value: value,
      },
    });
  };

  const sendAdminEmail = async (emailObject) => {
    const tokenResponse = await RequestAccessToken("protected");

    const accessToken = tokenResponse.accessToken;

    const url = `${serverUrl}/sendAdminEmail`;

    await axios.post(
      url,
      { emailObject },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    // console.log(res);
  };

  const sendEmail = async (emailObject) => {
    changeEmailState("sendState", "sending");
    const tokenResponse = await RequestAccessToken("graph");

    const accessToken = tokenResponse.accessToken;

    try {
      const res = await axios.post(
        `https://graph.microsoft.com/v1.0/me/sendMail`,
        { message: emailObject },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      console.log(res);
      changeEmailState("sendState", "sent");
    } catch (err) {
      console.log(err);
    }
  };

  const changeEmailRecipients = (recipientType, selectOptions) => {
    dispatch({
      type: UPDATE_RECIPIENTS,
      payload: {
        recipientType: recipientType,
        selectOptions: selectOptions,
      },
    });
  };

  const setEmailTemplate = (recipientType,selectOptions,bodyContent,subject)=>{
    dispatch({
      type:EMAIL_TEMPLATE,
      payload:{
        recipientType:recipientType,
        selectOptions:selectOptions,
        bodyContent:bodyContent,
        subject:subject
      }
    })
  }

  const clearMessage = () => {
    dispatch({
      type: CLEAR_MESSAGE,
      payload: ""
    });
  };

  return (
    <EmailContext.Provider
      value={{
        showEmailModal: state.showEmailModal,
        message: state.message,
        blankMessage: state.blankMessage,
        toRecipients: state.toRecipients,
        ccRecipients: state.ccRecipients,
        bccRecipients: state.bccRecipients,
        toOpen: state.toOpen,
        ccOpen: state.ccOpen,
        bccOpen: state.bccOpen,
        sendState: state.sendState,
        changeEmailState,
        changeEmailRecipients,
        clearMessage,
        sendEmail,
        sendAdminEmail,
        setEmailTemplate
      }}
    >
      {props.children}
    </EmailContext.Provider>
  );
};
